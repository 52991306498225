/* TopCategories.css */
.card-animate {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.7s ease, opacity 0.5s ease;
  }
  
  .card-animate.left-to-right {
    transform: translateX(-100%);
  }
  
  .card-animate.right-to-left {
    transform: translateX(100%);
  }
  
  .card-animate.visible {
    opacity: 1;
    transform: translateX(0);
  }
  
  .pill-custom {
    text-align: center;
    padding: 5px;
    border-radius: 35px;
    transition: transform 0.3s, box-shadow 0.4s;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .card-text-custom {
    font-size: 1rem;
    color: var(--dark);
  }
  
  .card-custom:hover {
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    .pill-custom {
      padding: 20px;
      font-size: 0.875rem;
    }
  
    .card-animate {
      transform: translateY(0);
    }
  
    .card-animate.left-to-right, 
    .card-animate.right-to-left {
      transform: translateX(0);
    }
  
    .card-text-custom {
      font-size: 0.875rem;
    }
  
    .mb-4 {
      margin-bottom: 1.5rem !important;
    }
  }
  
  .top-categories-section {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .primary-color {
    color: var(--primary);
    margin-bottom: 1.5rem;
  }
  
  @media (max-width: 576px) {
    .pill-custom {
      padding: 10px;
    }
  
    .card-text-custom {
      font-size: 0.75rem;
    }
  }
  