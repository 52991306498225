:root {
    --primary: #FF3A50;
    --primary-hover: #CC2F40;
    --secondary: #8D8280;
    --secondary-hover: #716964;
    --danger: #B21728;
    --danger-hover: #8D1020;
    --light: #FFFFFF;
    --dark: #231F20;
    --warning: #FFC107;
    --warning-hover: #CC9A06;
  }
  
  .modal-style {
    background-color: #fbfbfb;
    border: none;
    border-radius: 10px;
    padding: 30px 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  @media (max-width: 768px) {
    .modal-style {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  
  
  .progress-bar-custom .progress-bar {
    background-color: var(--primary);
  }
  
  .progress-bar-custom {
    margin: 20px 10px;
  }
  
  /* Success Component CSS */
  .success-alert .btn-primary {
    background-color: var(--primary);
    border: none;
  }
  
  .success-alert .btn-primary:hover {
    background-color: var(--primary);
  }
  
  .margin-top-15 {
    margin-top: 15px; 
  }
  
  .margin-bottom-15 {
    margin-bottom: 15px;
  }




/* JobDetail CSS */
.job-detail-container {
  margin-top: 30px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.job-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.job-detail-title {
  font-size: 2.5rem;
  font-weight: bold;
}

.job-detail-edit-btn {
  font-size: 1rem;
}

.job-detail-description,
.job-detail-category,
.job-detail-budget,
.job-detail-suburb {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.job-detail-image-single,
.job-detail-image-gallery {
  margin-top: 20px;
}

.job-detail-image-item {
  padding: 10px;
}

.job-detail-edit-form {
  margin-top: 20px;
}

.job-detail-save-btn {
  margin-top: 20px;
}

.job-detail-interactions {
  margin-top: 40px;
}

.image-container {
  max-width: 300px;
  margin: 0 auto;
}

.small-img {
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
}
