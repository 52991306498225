.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #343a40;
    color: white;
    padding: 10px 20px;
    z-index: 1000;
  }
  
  .cookie-consent-banner p {
    margin: 0;
    font-size: 14px;
  }
  
  .cookie-consent-banner .form-check {
    display: inline-block;
    margin-right: 10px;
  }
  
  .cookie-consent-banner .btn {
    background-color: #FF3A50;
    border-color: #FF3A50;
  }
  