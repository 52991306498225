/* src/App.css */
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

/* Custom primary colours */
:root {
  --primary: #FF3A50;
  --primary-hover: #CC2F40; /* Darker shade of primary */
  --secondary: #8D8280;
  --secondary-hover: #716964; /* Darker shade of secondary */
  --danger: #B21728;
  --danger-hover: #8D1020; /* Darker shade of danger */
  --light: #FFFFFF;
  --dark: #231F20;
  --warning: #FFC107; 
  --warning-hover: #CC9A06; /* Darker shade of warning */
}

.primary-color {
  color: var(--primary);
}

/* Override Bootstrap's default variables */
body {
  background-color: var(--light);
  color: var(--dark);
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary:disabled {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
}

.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary:disabled {
  background-color: var(--secondary-hover);
  border-color: var(--secondary-hover);
}

.btn-danger {
  background-color: var(--danger);
  border-color: var(--danger);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger:disabled {
  background-color: var(--danger-hover);
  border-color: var(--danger-hover);
}

.btn-warning {
  background-color: var(--warning);
  border-color: var(--warning);
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning:disabled {
  background-color: var(--warning-hover);
  border-color: var(--warning-hover);
}

/* Active and focus states for Nav links */
.nav-link.active,
.nav-link:focus {
  background-color: var(--primary) !important;
  color: var(--light) !important;
}

/* Additional Global CSS */
.p-side-menu-btn {
  margin-top: 10px;
  border: 1px solid;
}

.s-side-menu-btn {
  margin-top: 2px;
  border: 1px solid;
  margin-left: 30px;
}

.text-right {
  text-align: right;
}

/* Banner CSS */
.banner-section {
  background: linear-gradient(to bottom right, #480007, var(--primary));
  color: white;
  padding: 20px 0;
  margin-bottom: 10px;
  border-radius: 8px;
  position: relative;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.banner-title {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.banner-subtitle {
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.banner-button {
  padding: 10px 20px;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

.banner-benefits {
  list-style: none;
  padding: 0;
  margin-bottom: 15px;
}

.banner-benefits li {
  margin-bottom: 2px;
}

.list-no-points li {
  margin-bottom: 2px;
  list-style: none;
}

.close-banner-button {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.close-banner-button:hover {
  transform: scale(1.1);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .banner-title {
    margin-top: 25px;
  }
}


.address-suggestions {
  position: absolute;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.address-suggestions li {
  padding: 8px;
  cursor: pointer;
}

.address-suggestions li:hover {
  background-color: #f0f0f0;
}

#page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#content-wrap {
  flex-grow: 1;
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: var(--dark);
  color: white;
}

.footer-link:hover {
  text-decoration: underline;
  color: #adb5bd;
}
