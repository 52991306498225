/* Dashboard.css */
.dashboard-container {
    padding: 20px;
  }
  
  .stat-card {
    text-align: center;
  }
  
  .stat-icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .clickable-card {
    cursor: pointer;
  }
  