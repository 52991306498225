/* UserProfile.css */

/* Profile container */
.profile-container {
    background-color: var(--light);
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Profile cards */
  .profile-card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Profile title */
  .profile-title {
    color: var(--primary);
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  /* Profile image */
  .profile-image {
    /* border: 1px solid var(--primary); */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
  }
  
  /* Form control */
  .form-control {
    margin-bottom: 1rem;
  }
  
  /* Edit icon */
  .form-group .ms-2 {
    cursor: pointer;
    color: var(--secondary);
  }
  
  /* Save button */
  .btn-primary {
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active,
  .btn-primary.active {
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
  }
  
  /* Alert */
  .alert {
    margin-top: 1rem;
  }
  