/* General styles */
body {
  background-color: var(--light);
  color: var(--dark);
}

.text-right {
  text-align: right;
}

/* Banner Section */
.banner-container {
  position: relative;
  text-align: center;
  color: white;
  padding: 0;
}

.banner-image {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  position: relative;
}

.height-400 {
  max-height: 400px; 
}

.height-300 {
  max-height: 300px; 
}

.container-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  position: relative;
}

.overlay-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.primary-color {
  color: var(--primary);
}

/* Card Customization */
.card-custom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  padding-top: 20px;
  padding-bottom: 20px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.why-us-card {
  min-height: 70px
}

.card-custom i {
  font-size: 2rem;
  margin-bottom: 10px;
  color: var(--primary);
}

.card-custom:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.card-custom:hover i {
  color: var(--primary-hover);
}

/* Features Section */
.feature-section {
  padding: 60px 20px;
  background-color: #f9f9f9;
}

.feature-section .feature-card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature-section .feature-card:hover {
  transform: translateY(-5px);
}

/* How It Works Section */
.how-it-works-section {
  padding: 60px 20px;
  background-color: #ffffff;
}

.how-it-works-section .step {
  border: none;
}

.how-it-works-section .step-title {
  color: var(--primary);
  font-size: 1.5em;
  font-weight: bold;
}

/* Testimonials Section */
.testimonial-section {
  padding: 60px 20px;
  background-color: #f9f9f9;
}

.testimonial-card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  background: #f7f7f7;
  color: #333;
}

.testimonial-card:hover {
  transform: translateY(-5px);
}

/* Cookie Consent */
.cookie-consent {
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  text-align: center;
}

/* Create Job Banner */
.create-job-banner {
  background-color: var(--primary);
  color: white;
  padding: 40px 20px;
  text-align: center;
  margin-top: 30px;
  border-radius: 8px;
}

.create-job-banner .banner-text {
  max-width: 600px;
  margin: 0 auto;
}

/* Bootstrap Overrides */
:root {
  --primary: #FF3A50;
  --primary-hover: #CC2F40;
  --secondary: #8D8280;
  --secondary-hover: #716964;
  --danger: #B21728;
  --danger-hover: #8D1020;
  --light: #FFFFFF;
  --dark: #231F20;
  --warning: #FFC107;
  --warning-hover: #CC9A06;
}

@media (max-width: 768px) {
  .banner-container {
    padding: 0;
  }

  .banner-content h1 {
    font-size: 1.5rem;
  }

  .banner-content p {
    font-size: 0.9rem;
  }
}

.get-started-btn {
  max-width: 250px;
}

