.find-services-container {
  margin-top: 20px;
}

.service-item {
  background-color: #fbfbfb;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.service-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .list-service-logo {
    max-height: 150px;
    max-width: 150px;
  }
}

.service-card {
  height: 180px;
  border: none;
  background-color: transparent;
}

.service-card .card-body {
  padding: 0;
}

.service-card .card-title {
  font-size: 1.5rem;
  color: #333;
}

.service-card .card-text {
  font-size: 1rem;
  color: #555;
}

.bio-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
