/* HeaderFooter.css */
.custom-navbar {
  background-color: var(--dark);
}

.header-text {
  color: white;
}

.margin-right-10 {
  margin-right: 10px;
}

.custom-dropdown .dropdown-menu {
  right: 0;
  left: auto;
  transform: translateX(0);
}

@media (max-width: 767px) {
  .custom-dropdown .dropdown-menu {
    left: 0;
    right: auto;
  }
}

.header-brand {
  width: 180px;
  margin-left: 15px;
  height: auto;
}


.footer {
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-link:hover {
  text-decoration: underline;
  color: #adb5bd;
}

@media (max-width: 767px) {
  .footer .text-md-left {
    text-align: center !important;
  }
  
  .footer .text-md-right {
    text-align: center !important;
  }
}


/* Button styles */
.btn-outline-light {
  color: var(--light);
  border-color: var(--light);
  background-color: var(--primary);
  margin-left: 5px;
  border-radius: 10px;
}

.btn-outline-light:hover {
  color: var(--light);
  background-color: var(--primary-hover);
  border-color: var(--light);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header-brand .banner-image {
    width: 120px; /* Smaller logo for mobile */
  }

  .btn-outline-light {
    margin: 0.5rem 0;
    width: 100%;
  }

  .custom-navbar .navbar-nav {
    text-align: center;
  }
}
