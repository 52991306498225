/* ProjectsManager.css */
.project-list {
  max-height: 400px;
  overflow-y: auto;
}

.modal-body {
  max-height: 70vh;
  overflow-y: auto;
}

.form-group {
  margin-bottom: 1.5rem;
}

.projects-manager {
background-color: #f8f9fa;
padding: 20px;
}

.project-card {
cursor: pointer;
transition: transform 0.2s;
}

.project-card:hover {
transform: scale(1.02);
}

.project-members {
display: flex;
align-items: center;
gap: 5px;
}

.member-avatar {
width: 30px;
height: 30px;
background-color: #6c757d;
color: white;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
font-size: 14px;
}

.search-height {
height:38px;
}

/* Additional custom styles for alignment */
@media (min-width: 768px) {
.invite-row .form-control,
.invite-row .form-select {
  width: 100%;
}
}

.invite-row {
display: flex;
align-items: center;
}

.invite-row .btn {
display: flex;
align-items: center;
justify-content: center;
padding: 0.375rem 0.75rem;
}
