/* ProjectDashboard.css
/* Custom primary colours */
:root {
  --primary: #FF3A50;
  --primary-hover: #CC2F40; /* Darker shade of primary */
  --secondary: #8D8280;
  --secondary-hover: #716964; /* Darker shade of secondary */
  --danger: #B21728;
  --danger-hover: #8D1020; /* Darker shade of danger */
  --light: #FFFFFF;
  --dark: #231F20;
  --warning: #FFC107; 
  --warning-hover: #CC9A06; /* Darker shade of warning */
}

  /* General layout styles */
.project-dashboard-container {
  padding: 15px;
}

/* Menu column adjustments */
.menu-column {
  background-color: #f8f9fa;
  border-right: 1px solid #ddd;
  padding: 20px;
}

/* Component column styles */
.component-column {
  padding: 20px;
}

/* Buttons: Improving accessibility and touch size */
.btn {
  font-size: 1rem;
  padding: 10px 20px;
  text-align: left;
}

/* Mobile specific styles */
@media (max-width: 767px) {
  .menu-column {
    border-right: none;
    border-bottom: 1px solid #ddd;
  }
}

.menu-column {
  transition: width 0.3s ease;
}

.menu-column.collapsed {
  width: 60px; /* Adjust as needed */
}

.menu-toggle {
  cursor: pointer;
  padding: 10px;
}

.menu-content {
  transition: opacity 0.3s ease;
}

.menu-content.d-none {
  display: none;
}

.menu-content.d-block {
  display: block;
}

.menu-icons {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .menu-icons {
    flex-direction: row;
  }
}

.menu-icon {
  font-size: 1.5rem; /* Slightly increased size */
  margin: 10px;
  cursor: pointer;
}

.menu-icon:hover {
  color: var(--primary-hover); /* Change color on hover */
  position: relative;
}

.menu-icon:hover::after {
  content: attr(title); /* Show tooltip on hover */
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  bottom: -25px;
  left: 0;
}