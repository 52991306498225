.company-detail-container {
  padding-top: 50px;
}

.company-hero-section {
  background: #231F20;
  color: white;
  text-align: center;
  padding: 60px 20px;
  border-radius: 8px;
}

.company-logo {
  width: 100%;
  height: auto; 
  object-fit: cover;
}

.company-name {
  font-size: 2.5rem;
  font-weight: bold;
}

.company-tagline {
  font-size: 1.2rem;
  color: #8D8280;
  margin-top: 10px;
}

.company-card {
  background: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.company-card-body {
  padding: 30px;
}

.company-info {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.company-info strong {
  color: #FF3A50;
}

.contact-button {
  background-color: #FF3A50;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1.2rem;
  border-radius: 25px;
  transition: background 0.3s;
}

.contact-button:hover {
  background-color: #CC2F40;
}

@media (max-width: 768px) {
  .company-detail-container {
    padding-top: 20px;
  }

  .company-name {
    font-size: 2rem;
  }

  .company-info {
    font-size: 1rem;
  }

  .company-logo {
    width: 120px;
    height: auto; 
  }
}
