/* CompanySubMenu.css */

/* Custom primary colours */
:root {
  --primary: #FF3A50;
  --primary-hover: #CC2F40; /* Darker shade of primary */
  --secondary: #8D8280;
  --secondary-hover: #716964; /* Darker shade of secondary */
  --danger: #B21728;
  --danger-hover: #8D1020; /* Darker shade of danger */
  --light: #FFFFFF;
  --dark: #231F20;
  --warning: #FFC107; 
  --warning-hover: #CC9A06; /* Darker shade of warning */
}

.company-submenu {
  padding: 15px;
  background-color: var(--light);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 100vh; 
  display: flex;
  flex-direction: column;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .company-submenu {
    height: auto; 
  }
}

.company-dropdown {
  margin-bottom: 15px;
}

.company-dropdown-btn {
  background-color: var(--light);
  color: var(--dark);
  border-color: var(--primary);
  width: 100%;
  text-align: left;
}

.company-dropdown-btn:hover {
  color: var(--light);
  background-color: var(--primary);
  border-color: var(--primary);
}

.no-hover {
  cursor: default;
  color: inherit;
  text-decoration: none;
}

.no-hover:hover {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

.company-dropdown-toggle {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #FFFFFF;
}

.company-dropdown-toggle:active {
  background-color: var(--danger);
  border-color: var(--danger);
  color: #FFFFFF;
}

.company-dropdown-toggle:hover {
  color: var(--light);
  background-color: var(--primary);
  border-color: var(--primary);
}

/* Ensure dropdown is right-aligned */
.company-dropdown .dropdown-menu {
  width: auto; /* Adjust width if needed */
  text-align: right; /* Right-align text items */
  right: 0; /* Align dropdown to the right edge of the parent */
  left: auto; /* Prevent overriding the right alignment */
}

/* Optional: Add a border to see if alignment issues are present */
.company-dropdown .dropdown-menu {
  border: 1px solid red; /* For debugging alignment issues */
}


.company-dropdown .dropdown-menu {
  width: 100%;
  text-align: right; /* Right-align text items */
}

.full-width {
  width: calc(100% - 1px); /* Ensure the full button is clickable */
}

.submenu-link {
  color: var(--primary);
  font-weight: bold;
  padding: 10px;
  transition: color 0.3s, background-color 0.3s;
}

.submenu-link:hover {
  color: var(--light);
  background-color: var(--primary);
}

.add-service-btn {
  margin-top: auto;
  width: 100%;
  background-color: var(--primary);
  border: none;
  color: var(--light);
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  position: sticky;
  bottom: 0;
  transition: background-color 0.3s;
}

.add-service-btn:hover {
  background-color: var(--primary-hover);
}

.loading-container {
  text-align: center;
  padding: 20px;
}

.alert-danger {
  color: var(--light);
  background-color: var(--danger);
  border-color: var(--danger-hover);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .company-submenu {
    padding: 10px;
  }

  .company-dropdown-btn,
  .submenu-link,
  .add-service-btn {
    padding: 12px;
  }
}

/* Loading container */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

/* Alert */
.alert {
  margin-top: 1rem;
}

.service-logo {
  max-height: 250px;
  max-width: 250px;
}


/* CompanyForm.css */

.FormLabel {
  font-weight: bold;
  font-size: 1rem;
}

.FormControl {
  border-radius: 8px;
  padding: 10px;
  font-size: 0.95rem;
}

.FormGroup {
  margin-bottom: 15px;
}

textarea.FormControl {
  resize: none;
}

@media (max-width: 768px) {
  .FormLabel {
    font-size: 0.9rem;
  }

  .FormControl {
    font-size: 0.85rem;
    padding: 8px;
  }
}


.profile-preview-card {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
}

.profile-preview-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.profile-preview-header {
  background-color: var(--primary);
  color: var(--light);
  font-size: 1.25rem;
  padding: 10px;
}

.profile-preview-body {
  padding: 20px;
}

.profile-img-container {
  width: 150px;
  height: auto;
  margin: 0 auto;
}

.profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-company-name {
  font-weight: bold;
  font-size: 1.5rem;
  color: var(--dark);
}

.profile-email, .profile-address, .profile-bio, .profile-phone, .company-trade-type {
  color: var(--secondary);
}

.profile-preview-card .profile-bio {
  margin-top: 10px;
  font-style: italic;
}
