@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-calendars/styles/material.css';
@import '~@syncfusion/ej2-dropdowns/styles/material.css';
@import '~@syncfusion/ej2-gantt/styles/material.css';
@import '~@syncfusion/ej2-grids/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-layouts/styles/material.css';
@import '~@syncfusion/ej2-lists/styles/material.css';
@import '~@syncfusion/ej2-navigations/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-richtexteditor/styles/material.css';
@import '~@syncfusion/ej2-treegrid/styles/material.css';
  
  .unlinked-task {
    background-color: #f8d7da !important; /* Light red background */
    border-left: 5px solid #f5c6cb !important; /* Optional: Add a border to highlight */
  }

  .toolbar-btn {
    height: 30px;
    text-align: center;
    height: 0;
    line-height: 0;
    margin-bottom: 5px;
  }

.float-right {
  float: right;
}