/* src/components/BillingManagement/SubscriptionStyles.css */

/* Global Styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f8f9fa;
}

/* PaymentDisplay.css styles */
.payment-display-container {
  padding: 60px 20px;
}

.plan-card {
  transition: transform 0.3s;
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  overflow: hidden;
  position: relative;
}

.plan-card:hover {
  transform: translateY(-10px);
}

.highlight-plan {
  border: 2px solid #007bff;
}

.exclusive-badge {
  background-color: #ff5722;
  color: #fff;
  font-size: 0.9em;
  position: absolute;
  top: 40px;
  right: -50px;
  transform: rotate(45deg);
  width: 200px;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
}

.plan-card .card-title {
  font-size: 1.75em;
  margin-bottom: 15px;
}

.plan-card .card-subtitle {
  font-size: 1.25em;
  margin-bottom: 25px;
}

.plan-features {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}

.plan-features li {
  margin-bottom: 15px;
  font-size: 1em;
  display: flex;
  align-items: center;
}

.plan-features li::before {
  content: '✔';
  color: #28a745;
  margin-right: 10px;
}

.plan-button {
  border-radius: 30px;
  width: 80%;
  height: 50px;
  font-size: 1.1em;
  text-align: center;
}

.plan-button:hover {
  background-color: #0056b3;
}

.terms-and-conditions {
  font-size: 0.9em; 
  font-style: italic;
  color: #6c757d; 
  margin: 0 auto; 
}

/* Adjustments for smaller screens */
@media (max-width: 576px) {
  .exclusive-badge {
    width: 150px;
    right: -45px;
    top: 15px;
    font-size: 0.8em;
  }

  .plan-card {
    margin-bottom: 20px;
  }
}

/* SuccessDisplay.css styles */
.success-display-container {
  padding: 80px 20px;
}

.success-message {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 15px;
  border: none;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  display: inline-block;
}

.success-image {
  width: 100px;
  height: 100px;
}

.manage-billing-button {
  margin-top: 30px;
  border-radius: 30px;
  width: 60%;
  height: 50px;
  font-size: 1.1em;
}

/* SubscriptionList.css styles */
.subscription-list-container {
  padding: 60px 20px;
}

.subscription-card {
  transition: transform 0.3s;
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
}

.subscription-card:hover {
  transform: translateY(-5px);
}

.subscription-card .card-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.subscription-details {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.subscription-details li {
  margin-bottom: 10px;
  font-size: 1em;
}

.subscription-button {
  border-radius: 30px;
  width: 100%;
  height: 45px;
  font-size: 1em;
}

.subscription-button:hover {
  opacity: 0.9;
}
